import request from '../utils/request.js'

//头部


export function header(brandId) {
	return request({
		url: '/website/brandDetail/brandDetailByBrandId/detail/' + brandId,
		method: 'get',
	})
}


// export function header(obj) {
// 	return request({
// 		url: '/website/brandDetail/brandDetailByBrandId/detail',
// 		method: 'post',
// 		data: obj
// 	})
// }

//企业信息

export function info(brandId) {
	return request({
		url: '/website/brandDetail/entCompanyByBrandId/detail/' + brandId,
		method: 'get',
	})
}


//招商加盟
export function zhaoshang(brandId) {
	return request({
		url: '/website/brandDetail/inveBusinessByBrandId/detail/' + brandId,
		method: 'get',
	})
}


//相关品牌
export function pinpai(brandId) {
	return request({
		url: '/website/brandDetail/entCompanyBrandListByBrandId/detail/' + brandId,
		method: 'get',
	})
}

//相关网店
export function wangdian(brandId) {
	return request({
		url: '/website/brandDetail/mallShopListByBrandId/detail/' + brandId,
		method: 'get',
	})
}

//按行业查看
export function hangyeSee(brandId) {
	return request({
		url: '/website/brandDetail/categoryListByBrandId/detail/' + brandId,
		method: 'get',
	})
}

//按品牌查看
export function pinpaiSee(query) {
	return request({
		url: '/website/brandDetail/brandListByCategoryId/detail',
		method: 'get',
		params: query
	})
}




//列表接口 	模块位置，1:左侧文章列表,2:头条热榜,3:快讯
export function listPart (query) {
  return request({
    url: '/website/articletable/pageList',
    method: 'get',
    params: query
  })
}


//文章详情内容
export function articleDetail(id) {
	return request({
		url: '/website/articletable/detail/'+id,
		method: 'get',
	})
}

//文章详情右边
export function articleRight(query) {
	return request({
		url: '/website/articletable/detailPageList',
		method: 'get',
		params: query
	})
}



